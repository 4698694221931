/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  background: #f6f7f9 !important;
  color: #1b3a57;
  -webkit-font-smoothing: antialiased !important;
}

:root {
	--primary: #24A1DC;
	--search: #202327;
	--white: #FFFFFF;
	--black: #000000;
	--success: #95C23E;
	--danger: #E8265E;
	--warning: #CEC91E;
	--orange: #F7AB28;
	--info: #007BFF;
  --bg-dark: #424345;
  --bg-gray: #4b4c4f;
  --bg-gray-100: #757679;
}

$blue:         #2a9fd6 !default;
$yellow:       #CEC91E !default;
$green:        #77b300 !default;
$gray-200:     #e9ecef !default;

$warning:       $yellow !default;
$success:       $green !default;
$primary:       $blue !default;
$light:         $gray-200 !default;

@media(max-width: 1080px) {
  html {
    font-size: 93.75%;//15px
  }
}

@media(max-width: 720px) {
  html {
    font-size: 87.5%;//14px
  }
}

.app-body {
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;

  .main {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 0;
    min-height: calc(100vh - 120px);
  }
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase;
}

.uppercase {
  text-transform: uppercase;
}

button, select, textarea, input {
  font-family: Roboto,"Helvetica Neue",sans-serif;
}

.has-error {
  .ng-select:not(.ng-select-opened) {
    .ng-select-container {
      min-height: 35px !important;
      background: transparent;
      border: none;
    }
  }
}

.has-success {
  .ng-select:not(.ng-select-opened) {
    .ng-select-container {
      min-height: 35px !important;
      background: transparent;
      border: none;
    }
  }
}

.has-error .ng-select.ng-select-single .ng-select-container,
.has-success .ng-select.ng-select-single .ng-select-container {
    height: 35px !important;
    background: transparent;
    border: none;
}

input.ng-invalid.ng-touched,
ng-select.ng-invalid.ng-touched,
select.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
    border: 1px solid red;
    border-radius: 4px;
}

input.ng-valid.ng-touched,
ng-select.ng-valid.ng-touched,
select.ng-valid.ng-touched,
textarea.ng-valid.ng-touched {
    border: 1px solid green;
    border-radius: 4px;

}

button:disabled,
button[disabled]{
  cursor: no-drop;

  i {
    cursor: no-drop !important;
  }
}

.title-page {
  font-weight: 400;
}

.required {
  color: #ff0000;
}

.card {
  box-shadow: none;
  border: 0px !important;
}

.container-fluid {
  max-width: 1400px;
  padding-left: 80px !important;
  padding-right: 30px !important;
}

.container {
  padding-left: 80px !important;
  padding-right: 30px !important;
}

.table {
  th {
    border-top: none !important;
  }
}

.modal {
  z-index: 999999;

  .modal-header  {
    /* background-color: #07a287; */
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  button.close {
    color: #0e6e45;
    background-color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 0px 0px 0px auto;
    position: relative;

    span {
      position: absolute;
      top: 50%;
      left: 51%;
      transform: translate(-50%, -50%);
    }
  }
}

.container-fluid,
.container {
  @media (max-width: 992px) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}


.avatar-upload {
    position: relative;
    max-width: 150px;
    margin: 15px auto;

    .avatar-edit {
      position: absolute;
      right: 35px;
      z-index: 1;
      bottom: 5px;
    }

    .avatar-edit input {
      display: none;

      & + label {
        display: inline-block;
        width: 34px;
        height: 34px;
        margin-bottom: 0;
        border-radius: 100%;
        background: #FFFFFF;
        border: 1px solid transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        cursor: pointer;
        font-weight: normal;
        transition: all 0.2s ease-in-out;

      &:hover {
          background: #f1f1f1;
          border-color: #d6d6d6;
        }
      }

      &:after {
        content: "\f030";
        font-family: 'FontAwesome';
        color: #000;
        position: absolute;
        top: 5px;
        left: 1px;
        right: 0;
        text-align: center;
        margin: auto;
      }
    }

    .avatar-preview {
      width: 150px;
      height: 150px;
      position: relative;
      border-radius: 100%;
      border: 3px solid #ccc;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

      & > div {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #fff;
      }
    }

    .avatar-preview.required {
      border: 3px solid red;
    }

    .avatar-preview.success {
      border: 3px solid green;
    }

}

.no-scrool {
  overflow: hidden;
}

.toast-container {
  z-index: 9999999;
}

// Animation
@keyframes placeHolderShimmer{
  0%{
    background-position: -1700px 0
  }
  100%{
    background-position: 1700px 0
  }
}

.animated-background {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #F6F6F6;
  background: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  position: relative;
}

.placeholder-background {
  height: 60px;
  width: 100%;
//  background: #F6F6F6;
  @extend .animated-background;
}

#drawing-map {
  google-map {
    .map-container {
      height: 75vh !important;
      width: 100% !important;
    }
  }
}

.card-map-container {
  google-map {
    .map-container {
      width: 100% !important;
      height: 250px !important;
    }
  }
}
